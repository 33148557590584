import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  RECOVER_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  GET_USER_SUCCESS,
  SET_BUSINESS,
  SWITCH_TO_ADMIN_VIEW
} from "../constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('user_id') || null,
  user: JSON.parse(localStorage.getItem('user')) || {},
  refreshToken: localStorage.getItem('refreshToken') || null,
  business: null,
};


export default (state = INIT_STATE, action) => {

  switch (action && action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        // user: action.user
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        refreshToken: action.refreshToken,
        user: action.user,
        business: action.business,
        initUrl: ''
      }
    }
    case REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        authUser: action.payload,
        refreshToken: action.refreshToken,
        loader: false
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
        loader: false
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        user: null,
        initURL: '/index',
        loader: false,
        refreshToken: null,
        business: null
      }
    }
    case RECOVER_SUCCESS: {
      return {
        ...state,
        loader: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.user,
        business: action.business,
        // loader: false
      }
    }
    case SET_BUSINESS: { //only for SUPER_ADMIN
      return {
        ...state,
        business: action.payload
      }
    }
    case SWITCH_TO_ADMIN_VIEW:
      return {
        ...state,
        business: null
      };
    default:
      return {...state};
  }
}
