import React, { useEffect, useState } from "react";
import { WHITE_COLOR } from "../constants/ThemeColors";
import moment from "./moment";
import Avatar from "@material-ui/core/Avatar";
import placeholder from "../assets/images/placeholder.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "./IntlMessages";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SIGNOUT_USER } from "../constants/ActionTypes";

const Header = ({ currentTime, signOut, history }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [time, setTime] = useState("");

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const signOutt = () =>
    dispatch({
      type: SIGNOUT_USER,
    });

  const handleClick = (event) => {
    setDropdownOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setDropdownOpen(false);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format("HH:mm:ss"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={"app-wrapper d-flex pb-3 pt-3"}
      style={{
        backgroundColor: WHITE_COLOR,
        borderBottom: "0.5px solid",
        borderColor: "#c6c6c6",
        zIndex: 1,
        color: "#383838",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <h2 className={"mt-3"}>{time}</h2>
      </div>

      <div
        className={"d-flex m-0 header-user d-flex flex-row align-items-center"}
      >
        <p className={"mt-3 font-weight-light mr-3"}>
          <IntlMessages
            id={moment().hours() < 13 ? "goodMorning" : "goodEvening"}
          />
          , <span className={"font-weight-normal"}>{user && user.name}</span>!
        </p>
        <Avatar
          alt="profile"
          src={placeholder}
          className={"mt-auto mb-auto user-header-photo cursor-pointer"}
          onClick={handleClick}
        />
        <div className="user-detail">
          <h4 className="user-name" onClick={handleClick}>
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle text-muted" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={anchorEl}
          open={dropdownOpen}
          onClose={handleRequestClose}
          PaperProps={{
            style: {
              width: 136,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              handleRequestClose();
              // history.push('/app/account');
            }}
          >
            <i className="zmdi zmdi-account-circle zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.myAccount" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleRequestClose();
              signOutt();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
