import React from 'react';
import {
    CHANGE_DIRECTION,
    CHANGE_NAVIGATION_STYLE,
    DARK_THEME,
    DRAWER_TYPE,
    HORIZONTAL_MENU_POSITION,
    SWITCH_LANGUAGE,
    THEME_COLOR,
    TOGGLE_COLLAPSED_NAV,
    WINDOW_WIDTH,
    SEND_LANGUAGE_INFO,
    SWITCH_TO_ADMIN_VIEW,
    GET_LANGUAGES,
    GET_LANGUAGES_SUCCESS
} from '../constants/ActionTypes';
import settings from "../reducers/Settings";


export function toggleCollapsedNav(isNavCollapsed) {
    return {type: TOGGLE_COLLAPSED_NAV, isNavCollapsed};
}

export function setDrawerType(drawerType) {
    return {type: DRAWER_TYPE, drawerType};
}

export function updateWindowWidth(width) {
    return {type: WINDOW_WIDTH, width};
}

export function setThemeColor(color) {
    return {type: THEME_COLOR, color};
}

export function setDarkTheme() {
    return {type: DARK_THEME};
}

export function changeDirection() {
    return {type: CHANGE_DIRECTION};
}

export function changeNavigationStyle(layoutType) {
    return {
        type: CHANGE_NAVIGATION_STYLE,
        payload: layoutType
    };
}

export function setHorizontalMenuPosition(navigationPosition) {
    return {
        type: HORIZONTAL_MENU_POSITION,
        payload: navigationPosition
    };
}

export function switchLanguage(locale) {
    return {
        type: SWITCH_LANGUAGE,
        payload: locale
    };
}

export const getLanguage = (languages, keyName) => {
  let browserLanguage = settings().locale.locale;

  let currentLanguage = languages.filter(l => l.languageId).find( l => browserLanguage.toLowerCase().includes(l.languageId.toLowerCase())) || languages[0];

  return currentLanguage && currentLanguage[keyName] || <span className={'text-muted'}>-</span>;
};


export function switchToAdminView() {
    return {type: SWITCH_TO_ADMIN_VIEW}
}
