import React from 'react';
import Button from '../components/Button/Button';
import Input from '../components/Input/Input';
import CssBaseline from "@material-ui/core/CssBaseline";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import './SingIn.css';
import { BRAND_COLOR, SECONDARY_COLOR } from "../constants/ThemeColors";
import Notification from '../components/Notification/Notification';
import axios from "../util/axiosConfig";
import logoGreen from '../assets/images/freshperts-sm.svg'
import backgroundShape from "../assets/images/background-shape.png";
import customTheme from "./themes/customTheme";

class ValidateEmail extends React.Component {

    state = {
        showNotification: false,
        loading: true,
        success: false,
        errorId: null
    }

    showNotification = () => {
        this.setState({showMessage: true}, () => this.setState({showMessage: false}));
    };

    componentDidMount() {
        if(!this.props.match.params.token){
            this.props.history.push('/');
        }

        axios.get('/auth/email/validate?token='+this.props.match.params.token)
            .then(res => {
                this.setState({loading: false, success: true, errorId: null});
            })
            .catch(err => {
                this.showNotification();
                let errorId = 'validateEmailError';
                if (err.response){
                    if (err.response.status === 410){
                        errorId = 'validateEmailError.alreadyValidated';
                    } else if (err.response.status === 406){
                        errorId = 'validateEmailError.tokenExpired';
                    } else if (err.response.status === 404){
                        errorId = 'validateEmailError.tokenNotFound';
                    }
                }
                this.setState({loading: false, errorId, success: false});
            })

    }

    render() {
        const { showMessage, loading, errorId, success} = this.state;
        const applyTheme = createMuiTheme(customTheme({background: {
                backgroundImage: 'url('+backgroundShape+')',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundPosition: '15vw -16vh',
                backgroundSize: 'cover'
            }}));

        return (
            <MuiThemeProvider theme={applyTheme}>
                <CssBaseline />

                {!loading ?

                    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                        <div className="app-login-main-content" style={{borderRadius:0, height:'auto', width: '550px'}}>

                            <div className="app-login-content" style={{width: '100%', height: ''}}>
                                <div className="app-login-header mb-4">
                                    <div className="imgContainer">
                                        <img src={logoGreen} alt="logo1" className="logo"/>
                                    </div>

                                    <h1 style={{fontWeight: 'bold'}}><IntlMessages id={success ? "validateEmailSuccess" : errorId}/></h1>

                                </div>


                                <div className="app-login-form">


                                    <div className="mb-3 mt-6 d-flex align-items-center justify-content-between">
                                        <Button
                                            fullWidth
                                            bg={SECONDARY_COLOR}
                                            className={"text-white"}
                                            onClick={() => window.location = 'https://open.freshperts.es/zEih' }>
                                            <IntlMessages  id="openFreshpertsApp"/>
                                        </Button>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>

                    :

                    <div className="loader-view  ml-auto mr-auto">
                        <CircularProgress color='secondary'/>
                    </div>
                }

                <Notification showMessage={showMessage} title={"Error"} message={'Pruébelo más tarde o vuelva a solicitar el cambio de contraseña'} type={'danger'} />

            </MuiThemeProvider>
        );
    }
}

export default ValidateEmail;




