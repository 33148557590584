import freshpertsLogo from "../assets/images/logo-green.svg";
import backgroundShape from "../assets/images/background-shape-sm.png";
import favicon from "../assets/images/favicon.ico";
// -- GLOBAL
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:8080";
export const BASE_URL_KDS =
  process.env.REACT_APP_BASE_URL_KDS || "http://localhost:8080";
export const SOCKET_URL =
  process.env.REACT_APP_SOCKET_URL || "ws://localhost:8080";

export const API_VERSION_PRIVATE =
  process.env.REACT_APP_API_VERSION_PRIVATE || "/api/private/v1";

export const API_KEY_PRIVATE = process.env.REACT_APP_API_KEY_PRIVATE || "test";
export const API_KEY_WEBSOCKET = process.env.REACT_APP_SOCKET_KEY || "test";
export const API_KEY_GRAPHQL = process.env.REACT_APP_API_KEY_GRAPHQL || "test";

// -- CUSTOM
export const BRAND_COLOR = process.env.REACT_APP_BRAND_COLOR || "#4A6A1D";
export const SECONDARY_COLOR =
  process.env.REACT_APP_SECONDARY_COLOR || "#00E2BD";
export const BASE_NAME = process.env.REACT_APP_BASE_NAME || "Freshperts";
export const BASE_LOGO = process.env.REACT_APP_BASE_LOGO || freshpertsLogo;
export const FAVICON = process.env.REACT_APP_FAVICON || favicon;
