import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Button from "../components/Button/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hideMessage,
  showAuthLoader,
  showAuthMessage,
  userSignIn,
} from "../actions/Auth";
import customTheme from "./themes/customTheme";
import {
  BLACK_COLOR,
  BRAND_COLOR,
  PRIMARY_COLOR,
  SUCCESS_COLOR,
} from "../constants/ThemeColors";
import Notification from "../components/Notification/Notification";
import { TextField } from "@material-ui/core";
import backgroundShape from "../assets/images/background-shape.png";
import { BASE_LOGO } from "../constants/strings";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      form: {
        email: "",
        password: "",
      },
      isValid: true,
      validations: {
        email: true,
        password: true,
      },
      loading: false,
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    // if (this.props.authUser !== null) {
    //   this.props.history.push('/');
    // }
  }

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.signInHandler();
    }
  };

  signInHandler = () => {
    if (this.state.form.email && this.state.form.password) {
      this.props.showAuthLoader();
      this.props.userSignIn({
        email: this.state.form.email,
        password: this.state.form.password,
      });
    } else {
      this.props.showAuthMessage();
    }
  };

  validate = (name, value) => {
    switch (name) {
      case "email":
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return value && pattern.test(value);
      case "password":
        return value.length > 5;
      default:
        return true;
    }
  };

  handleChange = (name) => (event) => {
    let form = {
      ...this.state.form,
    };

    form[name] = event.target.value;

    let validations = {
      ...this.state.validations,
    };

    validations[name] = this.validate(name, form[name]);

    let isValid = true;

    for (let value of Object.entries(form)) {
      isValid = isValid && this.validate(value[0], value[1]);
      if (!isValid) {
        break;
      }
    }

    this.setState({
      form,
      validations,
      isValid,
    });
  };

  render() {
    const { form, isValid, validations, loading } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    const applyTheme = createMuiTheme(
      customTheme({
        background: {
          backgroundImage: "url(" + backgroundShape + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "15vw -16vh",
          backgroundSize: "cover",
        },
      })
    );

    return (
      <MuiThemeProvider theme={applyTheme}>
        <CssBaseline />
        <img
          src={BASE_LOGO}
          alt={"logo"}
          style={{
            height: "5%",
            position: "absolute",
            left: "6rem",
            top: "5rem",
          }}
        />

        {!loading && !loader ? (
          <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div
              className="app-login-main-content bg-white"
              style={{ borderRadius: 5, height: "auto", width: "550px" }}
            >
              <div
                className="app-login-content"
                style={{ width: "100%", height: "" }}
              >
                <div className="app-login-header mb-4">
                  <h1 style={{ fontWeight: "bold" }}>
                    {" "}
                    <IntlMessages id="welcomeMessage" />
                  </h1>
                  <h3>
                    <IntlMessages id="logInSubtitle" />.
                  </h3>
                </div>

                <div className="app-login-form">
                  <form>
                    <fieldset>
                      <TextField
                        value={form.email}
                        type="email"
                        onChange={this.handleChange("email")}
                        label={<IntlMessages id="emailAddress" />}
                        fullWidth
                        helperText={
                          validations.email ? (
                            ""
                          ) : (
                            <IntlMessages id="emailError" />
                          )
                        }
                        error={!validations.email}
                        margin="normal"
                        className="mt-0 mb-2"
                        variant="outlined"
                        onKeyDown={this.onKeyDown}
                      />

                      <TextField
                        value={form.password}
                        type="password"
                        label={<IntlMessages id="appModule.password" />}
                        fullWidth
                        helperText={
                          validations.password ? (
                            ""
                          ) : (
                            <IntlMessages id="passwordError" />
                          )
                        }
                        error={!validations.password}
                        onChange={this.handleChange("password")}
                        margin="normal"
                        variant="outlined"
                        onKeyDown={this.onKeyDown}
                      />

                      <div className="mb-3 d-flex align-items-center justify-content-between cursor-not-allowed">
                        <Button
                          fullWidth
                          disabled={!isValid}
                          bg={PRIMARY_COLOR}
                          onClick={this.signInHandler}
                        >
                          <IntlMessages id="signIn" />
                        </Button>
                      </div>

                      <div className={"row"} style={styles.linkContainer}>
                        <Link
                          to="/recover"
                          className={"mr-auto ml-auto"}
                          style={{ color: BLACK_COLOR }}
                        >
                          <span className={"ml-auto mr-auto"}>
                            {<IntlMessages id="recoverPassword" />}
                          </span>
                        </Link>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="loader-view  ml-auto mr-auto">
            <CircularProgress color="secondary" />
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  showAuthMessage,
})(SignIn);

const styles = {
  link: {
    color: "#00000060",
    marginLeft: "auto",
    marginRight: "auto",
  },
  linkContainer: {
    marginTop: "5%",
  },
};
