import React, {useState, useEffect} from 'react';
import { withStyles } from "@material-ui/core/styles/index";
import Dialog from "@material-ui/core/Dialog/index";
import MuiDialogActions from "@material-ui/core/DialogActions/index";
import MuiDialogContent from "@material-ui/core/DialogContent/index";
import MuiDialogTitle from "@material-ui/core/DialogTitle/index";
import ModalCloseNoSave from './ModalCloseNoSave';

const ThemeModal = withStyles({
  root: {
    backgroundColor: 'transparent',
    color: '#383838'
  },
})(Dialog);

const DialogContent = withStyles({
  root: {
    backgroundColor: '#fff',
    borderRadius: 0,
    color: '#383838',
    paddingTop: 0
  }
})(MuiDialogContent);


const DialogTitle = withStyles({
  root: {
    backgroundColor: '#fff',
    borderRadius: 0,
    color: '#383838',
    fontWeight: '700',
    fontSize: '1.5rem',
    paddingBottom: 0,
    paddingTop: 0
  }
})(MuiDialogTitle);

const DialogActions = withStyles({
  root: {
    backgroundColor: '#00E2BD',
    borderRadius: 0,
    color: '#383838',
    justifyContent: 'flex-start',
    paddingBottom: 8
  }
})(MuiDialogActions);


export const BaseModal = props =>  <ThemeModal PaperProps={{
  style: { borderRadius: 7 }
}} {...props}
/>;


const Modal = props => {

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (props.touched) {
      setOpen(true);
    } else {
      props.onClose ? props.onClose() : setOpen(false);
    }
  };

  useEffect( ()=>{
    handleClose();
  }, [props.close]);


  return (
    <>
      <BaseModal {...props}
                 onClose={handleClose}
      />
      <ModalCloseNoSave
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          props.onClose();
        }}
      />
    </>
  );
};


export const ModalContent = props =>  <DialogContent {...props} />;
export const ModalTitle = props =>  <DialogTitle {...props} />;
export const ModalActions = props =>  <DialogActions {...props} />;

export default Modal;
