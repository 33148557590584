import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "../../components/Button/Button";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import { connect } from "react-redux";
import { switchToAdminView } from "../../actions";
import { PRIMARY_COLOR, WHITE_COLOR } from "../../constants/ThemeColors";
import backgroundShape from "../../assets/images/background-shape-sm.png";
import { BASE_NAME } from "../../constants/strings";

class SidenavContent extends Component {
  state = {
    expanded: "",
  };

  handleExpanded = (expanded) => {
    this.setState({
      expanded,
    });
  };

  render() {
    const pathname = this.props.history.location.pathname;
    let { expanded } = this.state;

    return (
      <CustomScrollbars
        className=" scrollbar"
        style={{
          backgroundImage: "url(" + backgroundShape + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: 400,
          backgroundPosition: "-80px bottom",
        }}
      >
        <ul className="nav-menu">
          <li className="nav-header">
            <span className={"text-transform-none"}>
              <IntlMessages id="sidebar.title" />
            </span>
          </li>

          <li
            className={
              pathname.startsWith("/brands") || expanded === "brands"
                ? "menu collapse-box open"
                : "menu collapse-box"
            }
            onClick={() => this.handleExpanded("brands")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <span className="nav-text">
                <IntlMessages id="sidebar.establishment" />
              </span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink to="/brands" exact activeStyle={styles.activeNavLink}>
                  <span className="nav-text">
                    <IntlMessages id="sidebar.brands" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/brands/shops"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.shops" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/brands/kitchens"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.kitchens" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/brands/zones"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.zones" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li
            className={
              pathname.startsWith("/product") || expanded === "product"
                ? "menu collapse-box open"
                : "menu collapse-box"
            }
            onClick={() => this.handleExpanded("product")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <span className="nav-text">
                <IntlMessages id="sidebar.product" />
              </span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink
                  to="/product/products"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.products" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/product/productoverride"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.productOverride" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/product/promotions"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.promotions" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/product/categories"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.categories" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li
            className={
              pathname.startsWith("/clients") || expanded === "clients"
                ? "menu collapse-box open"
                : "menu collapse-box"
            }
            onClick={() => this.handleExpanded("clients")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <span className="nav-text">
                <IntlMessages id="sidebar.clients" />
              </span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink to="/clients/users" activeStyle={styles.activeNavLink}>
                  <span className="nav-text">
                    <IntlMessages id="sidebar.usersApp" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/clients/groups"
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.usersGroups" />
                  </span>
                </NavLink>
              </li>

              {/*<li>*/}
              {/*  <NavLink to="/clients/business" activeStyle={styles.activeNavLink}>*/}
              {/*    <span className="nav-text"><IntlMessages id="sidebar.usersWeb"/></span>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <NavLink to="/clients/business" activeStyle={styles.activeNavLink}>*/}
              {/*    <span className="nav-text"><IntlMessages id="sidebar.hotelsBusiness"/></span>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              <li>
                <NavLink
                  to="/clients/admins"
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.admin" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/*<li className="menu no-arrow">*/}
          {/*<Button bg={WHITE_COLOR} className={'text-dark mt-0'}>*/}
          {/*<NavLink to="/" className={'pl-0'}>*/}
          {/*<span className="nav-text"><IntlMessages id="sidebar.settings"/></span>*/}
          {/*</NavLink>*/}
          {/*</Button>*/}
          {/*</li>*/}

          <li
            className={
              pathname.startsWith("/settings") || expanded === "settings"
                ? "menu collapse-box open"
                : "menu collapse-box"
            }
            onClick={() => this.handleExpanded("settings")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <span className="nav-text">
                <IntlMessages id="sidebar.settings" />
              </span>
            </Button>

            <ul className="sub-menu">
              <li onClick={() => this.handleExpanded("settings")}>
                <NavLink
                  to="/settings/origins"
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.origins" />
                  </span>
                </NavLink>
              </li>
              <li onClick={() => this.handleExpanded("settings")}>
                <NavLink
                  to="/settings/languages"
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.languages" />
                  </span>
                </NavLink>
              </li>
              <li onClick={() => this.handleExpanded("settings")}>
                <NavLink
                  to="/settings/taxes"
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.taxes" />
                  </span>
                </NavLink>
              </li>
              <li onClick={() => this.handleExpanded("settings")}>
                <NavLink to="/settings/tags" activeStyle={styles.activeNavLink}>
                  <span className="nav-text">
                    <IntlMessages id="sidebar.tags" />
                  </span>
                </NavLink>
              </li>
              <li onClick={() => this.handleExpanded("settings")}>
                <NavLink
                  to="/settings/memberGetMember"
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="memberGetMember" />
                  </span>
                </NavLink>
              </li>
              <li onClick={() => this.handleExpanded("settings")}>
                <NavLink
                  to="/settings/payment-types"
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.paymentTypes" />
                  </span>
                </NavLink>
              </li>
              <li onClick={() => this.handleExpanded("settings")}>
                <NavLink
                  to="/settings/faq-app"
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.faq-app" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li
            className="menu no-arrow"
            onClick={() => this.handleExpanded("coupons")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <NavLink to="/coupons" className={"pl-0"}>
                <span className="nav-text">
                  <IntlMessages id="sidebar.coupons" />
                </span>
              </NavLink>
            </Button>
          </li>

          <li
            className="menu no-arrow"
            onClick={() => this.handleExpanded("orders")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <NavLink to="/orders" className={"pl-0"}>
                <span className="nav-text">
                  <IntlMessages id="sidebar.orders" />
                </span>
              </NavLink>
            </Button>
          </li>

          <li
            className={
              pathname.startsWith("/feedback") || expanded === "feedback"
                ? "menu collapse-box open"
                : "menu collapse-box"
            }
            onClick={() => this.handleExpanded("feedback")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <span className="nav-text">
                <IntlMessages id="sidebar.feedback" />
              </span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink
                  to="/feedback/pending"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.pending" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/feedback/all"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.all" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li
            className="menu no-arrow"
            onClick={() => this.handleExpanded("notifications")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <NavLink to="/notifications" className={"pl-0"}>
                <span className="nav-text">
                  <IntlMessages id="sidebar.notifications" />
                </span>
              </NavLink>
            </Button>
          </li>

          <li
            className="menu no-arrow"
            onClick={() => this.handleExpanded("features")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <NavLink to="/features" className={"pl-0"}>
                <span className="nav-text">
                  <IntlMessages id="sidebar.features" />
                </span>
              </NavLink>
            </Button>
          </li>

          <li
            className={
              pathname.startsWith("/deliverect") || expanded === "deliverect"
                ? "menu collapse-box open"
                : "menu collapse-box"
            }
            onClick={() => this.handleExpanded("deliverect")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <span className="nav-text">
                <IntlMessages id="sidebar.deliverect" />
              </span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink
                  to="/deliverect-locations"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.deliverectLocations" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/deliverect-channels"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.deliverectChannels" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/deliverect-payment-types"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.deliverectPaymentTypes" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li
            className={
              pathname.startsWith("/kds") || expanded === "kds"
                ? "menu collapse-box open"
                : "menu collapse-box"
            }
            onClick={() => this.handleExpanded("kds")}
          >
            <Button bg={WHITE_COLOR} className={"text-dark mt-0"}>
              <span className="nav-text">
                <IntlMessages id="sidebar.kds" />
              </span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink
                  to="/kds-screen-type"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.screenType" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/kds-screen-group"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.screenGroup" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/kds-screens"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.screens" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/kds-batch-type"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.batchType" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/kds-batch-group"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.batchGroup" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/kds-batch"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.batch" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/kds-brands"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.brands" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/kds-user"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.users" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/kds-cue-orders"
                  exact
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.kdsCueOrders" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>

        <div
          className={"pl-3 pb-3 fixed-bottom position-absolute opacity-50"}
          style={{ zIndex: -2 }}
        >
          <small
            className={"font-weight-light"}
            style={{ color: "rgba(56,56,56,0.51)" }}
          >
            {BASE_NAME} HUB 1.0
          </small>
        </div>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { business, loader } = auth;
  return { business, loading: loader };
};

export default withRouter(
  connect(mapStateToProps, { switchToAdminView })(SidenavContent)
);

const styles = {
  activeNavLink: {
    fontWeight: "500",
    color: PRIMARY_COLOR,
  },
};
