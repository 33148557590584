import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "../../actions/Auth";
import IntlMessages from "../../util/IntlMessages";
import placeholder from "../../assets/images/placeholder.png";

import Link from "react-router-dom/es/Link";
import { BASE_LOGO } from "../../constants/strings";

const UserInfo = (props) => {
  const { business } = props;
  return (
    <div
      className="user-profile d-flex flex-row align-items-center"
      style={{ backgroundColor: "#fff", minHeight: 100 }}
    >
      <div className={"d-flex flex-row mr-auto ml-auto align-items-center"}>
        <Link to={"/"}>
          <img
            alt="Freshperts logo"
            src={BASE_LOGO}
            style={{ width: "100%" }}
          />
        </Link>

        {business && (
          <>
            <div
              style={{
                borderLeft: 1,
                borderLeftStyle: "solid",
                borderColor: "#ececec",
                height: 40,
                marginLeft: 14,
                marginRight: 14,
              }}
            />
            <Avatar
              alt="user logo"
              src={business.image ? business.image : placeholder}
              className="user-avatar rounded-0"
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ settings, auth }) => {
  const { locale, isAdminView } = settings;
  const { user, business } = auth;
  return { locale, user, isAdminView, business };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
