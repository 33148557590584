import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    GET_USER,
    GET_USER_SUCCESS,
    SET_BUSINESS
} from "../constants/ActionTypes";

export const userSignUp = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user
    };
};
export const userSignIn = (user) => {
    return {
        type: SIGNIN_USER,
        payload: user
    };
};

export const userSignOut = () => {
    return {
        type: SIGNOUT_USER
    };
};
export const userSignUpSuccess = (authUser) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: authUser
    };
};

export const userSignInSuccess = (authUser, user, refreshToken, business) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: authUser,
        user: user,
        refreshToken: refreshToken,
        business: business
    }
};

export const subscribeTokenRefresh = cb => {
    return {
        type: REFRESH_TOKEN,
        payload: cb
    }
};

export const subscribeTokenRefreshSuccess = (authUser, refreshToken) => {

    return {
        type: REFRESH_TOKEN_SUCCESS,
        payload: authUser,
        refreshToken: refreshToken
    }
};

export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};


export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};
export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};

export const getUser = () => {
    return {
        type: GET_USER
    }
};

export const getUserSuccess = (payload) => {
    return {
        type: GET_USER_SUCCESS,
        user: payload.user,
        business: payload.business
    }
};

export const setBusiness = (business) => {
    return {
        type: SET_BUSINESS,
        payload: business
    }
};
