import React, { Suspense, lazy } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../components/Header/index";

import Sidebar from "../containers/SideNav/index";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "../constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "../components/TopNav";
import { RoleConsumer } from "../app/contexts/RoleContext";

//import { ThemeProvider } from "@material-ui/styles";
//import { createMuiTheme } from "@material-ui/core";
//import customTheme from "../containers/themes/customTheme";
import "../styles/custom.css";
import { MiscProvider } from "./contexts/DataContext";
import Header2 from "../util/Header";

const MainPage = lazy(() => import("./routes/MainPage"));
const Users = lazy(() => import("./routes/Users"));
const UserPage = lazy(() => import("./routes/Users/Components/UserPage"));
const UserGroups = lazy(() => import("./routes/UserGroups"));
const Admins = lazy(() => import("./routes/Users/Admins"));
const Brands = lazy(() => import("./routes/Brands"));
const ShopsPage = lazy(() => import("./routes/Shops"));
const Shops = lazy(() => import("./routes/Shops"));
const KitchensPage = lazy(() => import("./routes/Kitchens"));
const Kitchens = lazy(() => import("./routes/Kitchens"));
const Zones = lazy(() => import("./routes/Zones"));
const Products = lazy(() => import("./routes/Products"));
const EditProduct = lazy(() =>
  import("./routes/Products/Components/EditProduct")
);
const Configurable = lazy(() =>
  import("./routes/Products/Components/Configurable")
);
const Categories = lazy(() => import("./routes/Categories"));
const Promotions = lazy(() => import("./routes/Promotions"));
const PromotionPage = lazy(() =>
  import("./routes/Promotions/Components/PromotionPage")
);
const ProductOverride = lazy(() => import("./routes/ProductOverride"));
const ProductOverridePage = lazy(() =>
  import("./routes/ProductOverride/Components/ProductOverridePage")
);
const Origins = lazy(() => import("./routes/Origins"));
const Languages = lazy(() => import("./routes/Languages"));
const Taxes = lazy(() => import("./routes/Taxes"));
const Tags = lazy(() => import("./routes/Tags"));
const MemberGetMember = lazy(() => import("./routes/MemberGetMember"));
const PaymentTypes = lazy(() => import("./routes/PaymentTypes"));
const FaqApp = lazy(() => import("./routes/FaqApp"));
const Coupons = lazy(() => import("./routes/Coupons"));
const NewCoupon = lazy(() => import("./routes/Coupons/Components/NewCoupon"));
const EditCoupon = lazy(() => import("./routes/Coupons/Components/EditCoupon"));
const Orders = lazy(() => import("./routes/Orders"));
const OrderPage = lazy(() => import("./routes/Orders/Components/OrderPage"));
const Pending = lazy(() => import("./routes/Feedback/pending"));
const FeedbackAll = lazy(() => import("./routes/Feedback/all"));
const Notifications = lazy(() => import("./routes/Notifications"));
const NotificationPage = lazy(() =>
  import("./routes/Notifications/Components/NotificationPage")
);
const Features = lazy(() => import("./routes/Features"));
const FeaturePage = lazy(() =>
  import("./routes/Features/Components/FeaturePage")
);
const DeliverectLocations = lazy(() => import("./routes/DeliverectLocations"));
const DeliverectLocationsPage = lazy(() =>
  import("./routes/DeliverectLocations/Components/DeliverectPage")
);
const DeliverectChannels = lazy(() => import("./routes/DeliverectChannels"));
const DeliverectChannelsPage = lazy(() =>
  import("./routes/DeliverectChannels/Components/DeliverectPage")
);
const DeliverectPaymentTypes = lazy(() =>
  import("./routes/DeliverectPaymentTypes")
);
const DeliverectPaymentTypesPage = lazy(() =>
  import("./routes/DeliverectPaymentTypes/Components/DeliverectPage")
);

const KdsBatch = lazy(() => import("./routes/KdsBatch"));
const KdsBatchGroup = lazy(() => import("./routes/KdsBatchGroup"));
const KdsBatchType = lazy(() => import("./routes/KdsBatchType"));
const KdsBrands = lazy(() => import("./routes/KdsBrands"));
const KdsScreenGroup = lazy(() => import("./routes/KdsScreenGroup"));
const KdsScreens = lazy(() => import("./routes/KdsScreens"));
const KdsScreenType = lazy(() => import("./routes/KdsScreenType"));
const KdsUser = lazy(() => import("./routes/KdsUser"));
const KdsCueOrders = lazy(() => import("./routes/KdsCueOrders"));

const KdsBatchPage = lazy(() =>
  import("./routes/KdsBatch/Components/BatchPage")
);
const KdsBatchGroupPage = lazy(() =>
  import("./routes/KdsBatchGroup/Components/BatchGroupPage")
);
const KdsBatchTypePage = lazy(() =>
  import("./routes/KdsBatchType/Components/BatchTypePage")
);
const KdsBrandsPage = lazy(() =>
  import("./routes/KdsBrands/Components/BrandPage")
);
const KdsScreenGroupPage = lazy(() =>
  import("./routes/KdsScreenGroup/Components/ScreenGroupPage")
);
const KdsScreensPage = lazy(() =>
  import("./routes/KdsScreens/Components/ScreensPage")
);
const KdsScreenTypePage = lazy(() =>
  import("./routes/KdsScreenType/Components/ScreenTypePage")
);
const KdsUserPage = lazy(() => import("./routes/KdsUser/Components/UserPage"));
const KdsCueOrdersPage = lazy(() =>
  import("./routes/KdsCueOrders/Components/CueOrderPage")
);

const Error404 = lazy(() => import("../components/Error404"));

const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <RoleConsumer>
      {(user) => (
        <Route
          render={(props) => {
            props = { ...rest, ...props };
            return user ? <Component {...props} /> : null;
          }}
          {...rest}
        />
      )}
    </RoleConsumer>
  );
};

const App = (props) => {
  const { match, drawerType, navigationStyle, horizontalNavPosition } = props;
  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "fixed-drawer"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? "collapsible-drawer"
    : "mini-drawer";

  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add("ios-mobile-view-height");
  } else if (document.body.classList.contains("ios-mobile-view-height")) {
    document.body.classList.remove("ios-mobile-view-height");
  }
  //const applyTheme = createMuiTheme(customTheme());

  return (
    <MiscProvider>
      {/*<ThemeProvider theme={applyTheme}>*/}
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`d-block d-xl-none app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          {/*<RoleConsumer>{(user) => <Sidebar user={user} />}</RoleConsumer>*/}

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Header2 />
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route path={`/index`} component={MainPage} />

                  <AdminRoute
                    path={`/clients/users`}
                    exact
                    showHeader
                    component={Users}
                  />

                  <AdminRoute
                    path={`/clients/users/:id`}
                    exact
                    showHeader
                    component={UserPage}
                  />

                  <AdminRoute
                    path={`/clients/groups`}
                    exact
                    showHeader
                    component={UserGroups}
                  />

                  <AdminRoute
                    path={`/clients/admins`}
                    exact
                    showHeader
                    component={Admins}
                  />

                  <AdminRoute
                    path={`/brands`}
                    exact
                    showHeader
                    component={Brands}
                  />

                  <AdminRoute
                    path={`/brands/shops/:id`}
                    exact
                    showHeader
                    component={ShopsPage}
                  />

                  <AdminRoute
                    path={`/brands/shops`}
                    exact
                    showHeader
                    component={Shops}
                  />

                  <AdminRoute
                    path={`/brands/kitchens/:id`}
                    exact
                    showHeader
                    component={KitchensPage}
                  />

                  <AdminRoute
                    path={`/brands/kitchens`}
                    exact
                    showHeader
                    component={Kitchens}
                  />

                  <AdminRoute
                    path={`/brands/zones`}
                    exact
                    showHeader
                    component={Zones}
                  />

                  <AdminRoute
                    path={`/product/products`}
                    exact
                    showHeader
                    component={Products}
                  />

                  <AdminRoute
                    path={`/product/products/:id`}
                    exact
                    showHeader
                    component={EditProduct}
                  />

                  <AdminRoute
                    path={`/product/products/:id/configure`}
                    exact
                    showHeader
                    component={Configurable}
                  />

                  <AdminRoute
                    path={`/product/categories`}
                    exact
                    showHeader
                    component={Categories}
                  />

                  <AdminRoute
                    path={`/product/promotions`}
                    exact
                    showHeader
                    component={Promotions}
                  />

                  <AdminRoute
                    path={`/product/promotions/:id`}
                    exact
                    showHeader
                    component={PromotionPage}
                  />

                  <AdminRoute
                    path={`/product/productoverride`}
                    exact
                    showHeader
                    component={ProductOverride}
                  />

                  <AdminRoute
                    path={`/product/productoverride/:id`}
                    exact
                    showHeader
                    component={ProductOverridePage}
                  />

                  <AdminRoute
                    path={`/settings/origins`}
                    exact
                    showHeader
                    component={Origins}
                  />
                  <AdminRoute
                    path={`/settings/languages`}
                    exact
                    showHeader
                    component={Languages}
                  />
                  <AdminRoute
                    path={`/settings/taxes`}
                    exact
                    showHeader
                    component={Taxes}
                  />
                  <AdminRoute
                    path={`/settings/tags`}
                    exact
                    showHeader
                    component={Tags}
                  />
                  <AdminRoute
                    path={`/settings/memberGetMember`}
                    exact
                    showHeader
                    component={MemberGetMember}
                  />
                  <AdminRoute
                    path={`/settings/payment-types`}
                    exact
                    showHeader
                    component={PaymentTypes}
                  />
                  <AdminRoute
                    path={`/settings/faq-app`}
                    exact
                    showHeader
                    component={FaqApp}
                  />

                  <AdminRoute
                    path={`/coupons`}
                    exact
                    showHeader
                    component={Coupons}
                  />

                  <AdminRoute
                    path={`/coupon/newcoupon`}
                    exact
                    showHeader
                    component={NewCoupon}
                  />

                  <AdminRoute
                    path={`/coupons/coupon/:id`}
                    exact
                    showHeader
                    component={EditCoupon}
                  />

                  <AdminRoute
                    path={`/orders`}
                    exact
                    showHeader
                    component={Orders}
                  />

                  <AdminRoute
                    path={`/orders/order/:id`}
                    exact
                    showHeader
                    component={OrderPage}
                  />

                  <AdminRoute
                    path={`/feedback/pending`}
                    exact
                    showHeader
                    component={Pending}
                  />

                  <AdminRoute
                    path={`/feedback/all`}
                    exact
                    showHeader
                    component={FeedbackAll}
                  />

                  <AdminRoute
                    path={`/notifications`}
                    exact
                    showHeader
                    component={Notifications}
                  />

                  <AdminRoute
                    path={`/notifications/notification/:id`}
                    exact
                    showHeader
                    component={NotificationPage}
                  />

                  <AdminRoute
                    path={`/features`}
                    exact
                    showHeader
                    component={Features}
                  />

                  <AdminRoute
                    path={`/features/feature/:id`}
                    exact
                    showHeader
                    component={FeaturePage}
                  />

                  <AdminRoute
                    path={`/clients/business`}
                    exact
                    component={Brands}
                  />

                  <AdminRoute
                    path={`/deliverect-locations`}
                    exact
                    showHeader
                    component={DeliverectLocations}
                  />

                  <AdminRoute
                    path={`/deliverect-locations/:id`}
                    exact
                    showHeader
                    component={DeliverectLocationsPage}
                  />

                  <AdminRoute
                    path={`/deliverect-channels`}
                    exact
                    showHeader
                    component={DeliverectChannels}
                  />

                  <AdminRoute
                    path={`/deliverect-channels/:id`}
                    exact
                    showHeader
                    component={DeliverectChannelsPage}
                  />

                  <AdminRoute
                    path={`/deliverect-payment-types`}
                    exact
                    showHeader
                    component={DeliverectPaymentTypes}
                  />

                  <AdminRoute
                    path={`/deliverect-payment-types/:id`}
                    exact
                    showHeader
                    component={DeliverectPaymentTypesPage}
                  />

                  <AdminRoute
                    path={`/kds-screen-type`}
                    exact
                    showHeader
                    component={KdsScreenType}
                  />

                  <AdminRoute
                    path={`/kds-screen-type/:id`}
                    exact
                    showHeader
                    component={KdsScreenTypePage}
                  />

                  <AdminRoute
                    path={`/kds-screen-group`}
                    exact
                    showHeader
                    component={KdsScreenGroup}
                  />

                  <AdminRoute
                    path={`/kds-screen-group/:id`}
                    exact
                    showHeader
                    component={KdsScreenGroupPage}
                  />

                  <AdminRoute
                    path={`/kds-screens`}
                    exact
                    showHeader
                    component={KdsScreens}
                  />

                  <AdminRoute
                    path={`/kds-screens/:id`}
                    exact
                    showHeader
                    component={KdsScreensPage}
                  />

                  <AdminRoute
                    path={`/kds-batch-type`}
                    exact
                    showHeader
                    component={KdsBatchType}
                  />

                  <AdminRoute
                    path={`/kds-batch-type/:id`}
                    exact
                    showHeader
                    component={KdsBatchTypePage}
                  />

                  <AdminRoute
                    path={`/kds-batch-group`}
                    exact
                    showHeader
                    component={KdsBatchGroup}
                  />

                  <AdminRoute
                    path={`/kds-batch-group/:id`}
                    exact
                    showHeader
                    component={KdsBatchGroupPage}
                  />

                  <AdminRoute
                    path={`/kds-batch`}
                    exact
                    showHeader
                    component={KdsBatch}
                  />

                  <AdminRoute
                    path={`/kds-batch/:id`}
                    exact
                    showHeader
                    component={KdsBatchPage}
                  />

                  <AdminRoute
                    path={`/kds-brands`}
                    exact
                    showHeader
                    component={KdsBrands}
                  />

                  <AdminRoute
                    path={`/kds-brands/:id`}
                    exact
                    showHeader
                    component={KdsBrandsPage}
                  />

                  <AdminRoute
                    path={`/kds-user`}
                    exact
                    showHeader
                    component={KdsUser}
                  />

                  <AdminRoute
                    path={`/kds-user/:id`}
                    exact
                    showHeader
                    component={KdsUserPage}
                  />

                  <AdminRoute
                    path={`/kds-cue-orders`}
                    exact
                    showHeader
                    component={KdsCueOrders}
                  />

                  <AdminRoute
                    path={`/kds-cue-orders/:id`}
                    exact
                    showHeader
                    component={KdsCueOrdersPage}
                  />

                  <Route path={`${match.url}/`} exact component={MainPage} />

                  <Route component={Error404} />
                </Switch>
              </Suspense>
            </div>
          </main>
        </div>
      </div>
      {/*</ThemeProvider>*/}
    </MiscProvider>
  );
};

const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition, auth };
};
export default withRouter(connect(mapStateToProps)(App));
