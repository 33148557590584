import React, { Component } from "react";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { split, HttpLink, ApolloClient } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "apollo-link";
import { Helmet } from "react-helmet";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import customTheme from "./themes/customTheme";
import AppLocale from "../lngProvider";
import MainApp from "../app/index";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Recover from "./Recover";
import {
  setInitUrl,
  subscribeTokenRefresh,
  getUser,
  hideAuthLoader,
} from "../actions";
import asyncComponent from "util/asyncComponent";
import { RoleProvider } from "../app/contexts/RoleContext";
import axios from "../util/axiosConfig";
import Notification from "../components/Notification/Notification";
import AppLoader from "../components/AppLoader/AppLoader";
import ResetPassword from "./ResetPassword";
import ValidateEmail from "./ValidateEmail";
import { ApolloProvider, InMemoryCache } from "@apollo/client";
import {
  API_KEY_GRAPHQL,
  BASE_URL,
  SOCKET_URL,
  BASE_URL_KDS,
} from "../constants/strings";
import { BASE_NAME, FAVICON } from "../constants/strings";

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
  },
};

const apolloClient = (token) => {
  const httpLink = createUploadLink({
    uri: BASE_URL + "/graphql",
    headers: {
      "x-api-key": API_KEY_GRAPHQL,
      Authorization: `Bearer ${token}`,
    },
  });

  const thirdPartyLink = new HttpLink({
    uri: BASE_URL_KDS + "/graphql",
    headers: {
      "x-api-key": API_KEY_GRAPHQL,
      Authorization: `Bearer ${token}`,
    },
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: SOCKET_URL + "/graphql",
      connectionParams: {
        // authToken: token,
        "x-api-key": API_KEY_GRAPHQL,
        Authorization: `Bearer ${token}`,
      },
      shouldRetry: () => {
        return true;
      }
    }),
  );
  // const wsLink = new WebSocketLink({
  //   uri: SOCKET_URL + "/graphql",
  //   options: {
  //     reconnect: true,
  //     connectionParams: {
  //       // authToken: token,
  //       "x-api-key": API_KEY_GRAPHQL,
  //       Authorization: `Bearer ${token}`,
  //     },
  //   },
  // });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("user_id");
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const httpLinks = ApolloLink.split(
    (operation) => operation.getContext().clientName === "third-party",
    thirdPartyLink,
    httpLink
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLinks
  );

  return new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
  });
};

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  state = {
    counter: true,
    apolloClient: apolloClient(this.props.authUser),
  };

  componentDidMount() {
    this.props.loader &&
      setTimeout(() => {
        this.setState({ counter: false });
      }, 1500);

    if (this.props.authUser) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.authUser;
      this.props.setInitUrl(this.props.history.location.pathname);

      const hasRefreshToken = this.props.refreshToken;
      const { subscribeTokenRefresh } = this.props;

      axios.interceptors.response.use(
        function(config) {
          return config;
        },
        function(error) {
          const { config } = error;

          if (
            config &&
            !config.url.endsWith("refresh") &&
            (error.response.status === 403 || error.response.status === 401)
          ) {
            if (!hasRefreshToken) {
              localStorage.removeItem("user_id");
              localStorage.removeItem("user");
              window.location.href = "/";
              return;
            }

            return new Promise((resolve) => {
              subscribeTokenRefresh((token) => {
                config.headers.Authorization = `Bearer ${token}`;
                resolve(axios(config));
              });
            });
          }
          return Promise.reject(error);
        }
      );

      this.props.getUser();
    } else {
      if (this.props.initURL === "") {
        this.props.setInitUrl("/");
      }
    }
  }

  render() {
    const {
      match,
      location,
      locale,
      authUser,
      initURL,
      user,
      loader,
      showMessage,
      authMessage,
      hideAuthLoader,
    } = this.props;

    if (location.pathname.startsWith("/signin") && authUser) {
      hideAuthLoader();
      return <Redirect to={"/"} />;
    }

    const applyTheme = createMuiTheme(customTheme({}));

    const currentAppLocale = AppLocale[locale.locale];

    return (
      <MuiThemeProvider theme={applyTheme}>
        <RoleProvider value={user}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}
            >
              <ApolloProvider client={this.state.apolloClient}>
                <div className="app-main">
                  <Helmet>
                    <title>{BASE_NAME} - Admin Dashboard</title>
                    <link
                      rel="icon"
                      type="image/png"
                      href={FAVICON}
                      sizes="16x16"
                    />
                  </Helmet>
                  {!this.props.location.pathname.startsWith("/signin") &&
                  this.state.counter ? (
                    <AppLoader />
                  ) : (
                    <Switch>
                      <Route path="/signup" component={SignUp} />
                      <Route path="/reset/:token" component={ResetPassword} />
                      <Route path="/recover" component={Recover} />
                      <Route
                        path="/validate/:token"
                        component={ValidateEmail}
                      />
                      <Route path="/signin" exact component={SignIn} />
                      <Route path="/signin/:id" component={SignIn} />
                      <RestrictedRoute
                        path={match.url}
                        authUser={authUser}
                        component={MainApp}
                      />
                      <Route
                        component={asyncComponent(() =>
                          import("../components/Error404")
                        )}
                      />
                    </Switch>
                  )}
                  <Notification
                    showMessage={showMessage}
                    message={authMessage}
                    type={"danger"}
                  />
                </div>
              </ApolloProvider>
            </IntlProvider>
          </MuiPickersUtilsProvider>
        </RoleProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale } = settings;
  const {
    authUser,
    initURL,
    user,
    refreshToken,
    loader,
    showMessage,
    authMessage,
    hideMessage,
  } = auth;
  return {
    sideNavColor,
    locale,
    authUser,
    initURL,
    user,
    refreshToken,
    loader,
    showMessage,
    authMessage,
    hideMessage,
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  subscribeTokenRefresh,
  getUser,
  hideAuthLoader,
})(App);
