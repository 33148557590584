import React, { useState, Component} from 'react';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';
import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import moment from "moment";
import Avatar from '@material-ui/core/Avatar';
import { connect } from "react-redux";
import { userSignOut } from "../actions";
import IntlMessages from 'util/IntlMessages';
import {WHITE_COLOR} from "../constants/ThemeColors";
import placeholder from '../assets/images/placeholder.png';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Notification from "../components/Notification/Notification";
import {MiscDataContext} from "../app/contexts/DataContext";

const Header = ({user, currentTime, signOut, history}) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setDropdownOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setDropdownOpen(false);
  };

  return (
  <div className={"app-wrapper d-flex pb-3 pt-3"} style={{backgroundColor: WHITE_COLOR, borderBottom: '0.5px solid', borderColor: '#c6c6c6',  zIndex: 1, color: '#383838'}}>
    <div style={{flexGrow: 1}}>
      <h2 className={'mt-3'}>{currentTime}</h2>
    </div>

    <div className={"d-flex m-0 header-user d-flex flex-row align-items-center"}>
      <p className={'mt-3 font-weight-light mr-3'}><IntlMessages id={moment().hours() < 13 ? "goodMorning" : "goodEvening"}/>, <span className={"font-weight-normal"}>{user && user.name}</span>!</p>
      <Avatar alt="profile" src={placeholder} className={'mt-auto mb-auto user-header-photo cursor-pointer'} onClick={handleClick} />
      <div className="user-detail">
        <h4 className="user-name" onClick={handleClick}>
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle text-muted"/>
        </h4>
      </div>
      <Menu className="user-info"
            id="simple-menu"
            anchorEl={anchorEl}
            open={dropdownOpen}
            onClose={handleRequestClose}
            PaperProps={{
              style: {
                width: 136,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
      >
        <MenuItem onClick={() => {
          handleRequestClose();
          // history.push('/app/account');
        }}>
          <i className="zmdi zmdi-account-circle zmdi-hc-fw mr-2"/>
          <IntlMessages id="popup.myAccount"/>
        </MenuItem>
        <MenuItem onClick={() => {
          handleRequestClose();
          signOut();
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
          <IntlMessages id="popup.logout"/>
        </MenuItem>
      </Menu>
    </div>
  </div>
)
}


const asyncComponent = (importComponent) => {

  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.showHeader = !this.props.hideHeader;
      this.state = {
        component: null,
        ...this.showHeader && {currentTime: moment().format("HH:mm:ss")},
        newOrder: {},
        notification: {
          open: false,
          message: '',
        }
      };
    }

    componentWillMount() {
      Nprogress.start();
    }

    componentWillUnmount() {
      if (this.showHeader) {
        clearInterval(this.intervalID);
      }
      this.mounted = false;
    }

    async componentDidMount() {
        this.mounted = true;
      const {default: Component} = await importComponent();
      Nprogress.done();
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />
        });
      }

      if (this.showHeader){
        this.intervalID = setInterval(
          () => {
            this.setState({currentTime: moment().format("HH:mm:ss")});
          },
          1000
        );
      }
    }

    componentDidUpdate() {
      AsyncFunc.contextType = MiscDataContext;
      const newOrder = this.context.newOrder;

      if(this.state.newOrder !== newOrder){
        this.setState({newOrder: newOrder});

        if (newOrder && newOrder.action === 'order_new') {
          this.showNotification("Ha llegado un pedido nuevo.", "default");

        }  else if(newOrder && newOrder.action === 'order_update') {
          this.showNotification( "Se ha actualizado un pedido", "default");
        }
      }
    }

    showNotification = (message) => {
      this.setState({
        notification: {
          open: true,
          message: message
        }
      }, () => this.setState({
        notification: {
          open: false,
        }
      }));
    };

    render() {
      const Component = this.state.component || <div/>;
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {this.showHeader &&
          <Header currentTime={this.state.currentTime}
                  user={this.props.user}
                  signOut={this.props.userSignOut}
          />}
          {Component}
          <Notification showMessage={this.state.notification.open} message={this.state.notification.message} type={'default'} />
        </ReactPlaceholder>
      );
    }
  }


  const mapStateToProps = ({auth}) => {
    const {user} = auth;
    return {user};
  };

  return connect(mapStateToProps, {userSignOut})(AsyncFunc);
};

export default asyncComponent;
