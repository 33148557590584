import React, { useState, useEffect } from "react";
import { useSubscription, gql, useQuery } from "@apollo/client";
import axios from "../../util/axiosConfig";
import { connect } from "react-redux";
import socketIOClient from "socket.io-client";
import {
  API_KEY_WEBSOCKET,
  SOCKET_URL,
  SOCKET_GRAPH,
} from "../../../src/constants/strings";
export const MiscDataContext = React.createContext({});

const mapStateToProps = ({ settings, auth }) => {
  return { settings, auth };
};

const ORDER_SUBSCRIPTION = gql`
  subscription OrderEvents {
    orderEvents {
      id
      user {
        email
      }
      status
    }
  }
`;

export const MiscProvider = connect(mapStateToProps)((props) => {
  const [tags, setTags] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsOverride, setProductsOverride] = useState([]);
  const [shops, setShops] = useState([]);
  const [zones, setZones] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [kitchens, setKitchens] = useState([]);
  const [activeKitchens, setActiveKitchens] = useState([]);
  const [privileges, setPrivileges] = useState([]);
  const [newOrder, setNewOrder] = useState({});
  const [newOrderSubs, setNewOrderSubs] = useState({});
  const [users, setUsers] = useState({});
  const [orders, setOrders] = useState({});

  const getData = () => {
    getTags();
    getPaymentTypes();
    getTaxes();
    getBrands();
    getCategories();
    getLanguages();
    getShops();
    getZones();
    getKitchens();
    getActiveKitchens();
    getProducts();
    getUsers();
    getOrders();
  };

  // const initializeWebSocket = () => {
  //   if (props.auth.authUser) {
  //     const socket = socketIOClient(
  //       SOCKET_URL +
  //         "?x-api-key=" +
  //         API_KEY_WEBSOCKET +
  //         "&authorization=" +
  //         props.auth.authUser,
  //       {
  //         transports: ["websocket"],
  //       }
  //     );
  //     socket.io.reconnectionAttempts(3);

  //     socket.on("connect", function() {
  //       // console.log("connected");
  //     });
  //     socket.on("order_events", function(event) {
  //       setNewOrder(event);
  //       setNewOrder({});
  //     });
  //     socket.on("disconnect", function() {
  //       // console.log("Client disconnected")
  //     });
  //   }
  // };

  const { data: dataOrder, loading } = useSubscription(ORDER_SUBSCRIPTION);

  useEffect(() => {
    getData();
    getPrivileges();
    // setOrders(data);
    // initializeWebSocket();
  }, [dataOrder]);

  useEffect(() => {
    setNewOrderSubs(dataOrder);
  }, [dataOrder]);

  const getTags = () => {
    axios
      .get("/tag/all")
      .then((res) => {
        setTags(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getPaymentTypes = () => {
    axios
      .get("/paymentType/all")
      .then((res) => {
        setPaymentTypes(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getTaxes = () => {
    axios
      .get("/tax/all")
      .then((res) => {
        setTaxes(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getBrands = () => {
    axios
      .get("/brand/all")
      .then((res) => {
        setBrands(res.data);
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getCategories = () => {
    axios
      .get("/product/category/all")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  const getProducts = () => {
    axios
      .get("/product/all")
      .then((res) => {
        setProducts(res.data.filter((a) => !a.configurable));
        setProductsOverride(res.data);
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getShops = () => {
    axios
      .get("/shop/all")
      .then((res) => {
        setShops(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getZones = () => {
    axios
      .get("/zone/all")
      .then((res) => {
        setZones(res.data.content);
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getLanguages = () => {
    axios
      .get("/language/all")
      .then((res) => {
        setLanguages(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getKitchens = () => {
    axios
      .get("/kitchen/all")
      .then((res) => {
        setKitchens(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getActiveKitchens = () => {
    axios
      .get("/kitchen")
      .then((res) => {
        let onlyActiveKitchens = res.data.content.filter((k) => k.activeDelivery === true).filter((k) => k.activeTakeAway === true);
        setActiveKitchens(onlyActiveKitchens);
        // console.log(onlyActiveKitchens);
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getPrivileges = () => {
    axios
      .get("/user/privilege")
      .then((res) => {
        setPrivileges(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getUsers = () => {
    axios
      .get("/user/all")
      .then((res) => {
        setUsers(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const getOrders = () => {
    axios
      .post("/order")
      .then((res) => {
        setOrders(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err.response)
      });
  };

  const detectAvailableLanguages = (
    usedLanguages,
    newLanguages,
    currentLanguageId
  ) => {
    try {
      let currentNewAvailableLanguages = [...languages];
      currentNewAvailableLanguages = currentNewAvailableLanguages.filter(
        (cu) => {
          let keep = true;
          for (let el of usedLanguages) {
            if (el.languageId === cu.id) {
              keep = false;
              break;
            }
          }
          return keep;
        }
      );

      currentNewAvailableLanguages = currentNewAvailableLanguages.filter(
        (cu) => {
          let keep = true;
          for (let el of newLanguages) {
            if (el.languageId === cu.id) {
              keep = false;
              break;
            }
          }
          return keep;
        }
      );

      let currentLanguage = languages.find((d) => d.id === currentLanguageId);

      currentLanguage && currentNewAvailableLanguages.push(currentLanguage);

      return currentNewAvailableLanguages;
    } catch (err) {
      // console.error(err);
      return [];
    }
  };

  const getSomeProducts = (startIndex, stopIndex) => {
    return products.slice(startIndex, stopIndex);
  };

  const getSearchedProducts = (text) => {
    let localId = props.settings.locale.locale;
    let filteredProducts = [];
    products.map((p) =>
      p.translations.map((t) => {
        let name = t.name.toLowerCase();
        if (
          name.includes(text.toLowerCase()) &&
          t.languageId.toLowerCase() === localId.toLowerCase()
        ) {
          filteredProducts.push(p);
        }
      })
    );
    return filteredProducts;
  };

  return (
    <MiscDataContext.Provider
      value={{
        tags,
        paymentTypes,
        taxes,
        brands,
        categories,
        products,
        shops,
        zones,
        languages,
        kitchens,
        activeKitchens,
        privileges,
        orders,
        newOrder,
        users,
        getData,
        getCategories,
        detectAvailableLanguages,
        getLanguages,
        getKitchens,
        getActiveKitchens,
        getProducts,
        getSomeProducts,
        getSearchedProducts,
        newOrderSubs,
        productsOverride,
      }}
    >
      {props.children}
    </MiscDataContext.Provider>
  );
});

export const MiscConsumer = MiscDataContext.Consumer;